import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { getAllDrivers } from '../api/services/Drivers.js';
import DriverTable from '../components/Drivers/DriverTable.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Drivers = () => {
  const axiosPrivate = useAxiosPrivate();

  const driverUrl = getAllDrivers();

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    getDrivers();
  }, []);

  const getDrivers = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(driverUrl, { params: { active: true } });
      const drivers = response?.data;
      console.log('DRIVERS::', drivers);
      setDrivers(drivers);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError('Failed to load data, try entering page again.');
      toast.error('Something went wrong...');
      setLoading(false);
    }
  };

  return (
    <>
      <DriverTable drivers={drivers} loading={loading} error={error} getDrivers={getDrivers} />
    </>
  );
};

export default Drivers;
