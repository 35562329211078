import { useState } from 'react';

import { toast } from 'react-toastify';

import { sendPasswordEmailToDriver } from '../../api/services/Drivers.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate.js';
import Button from '../Button';

export const EmailButton = ({ driver, getDrivers }) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const sendPasswordEmail = async () => {
    setLoading(true);
    const updateUserUrl = sendPasswordEmailToDriver(driver?.id);
    try {
      await axiosPrivate.post(updateUserUrl);
      getDrivers();
      toast.success(`Password email sent to ${driver.firstName} ${driver.lastName}!`);
    } catch (error) {
      console.log(error);
      toast.error('Failed to send email...');
    }
    setLoading(false);
  };

  if (driver.isEmailSent) {
    return <Button text="Sent" disabled={true} small={true} bg="bg-white" textColor="text-gray-500" border="border-gray-300" />;
  }

  return (
    <Button
      text={loading ? 'Sending...' : 'Send Email'}
      onClick={sendPasswordEmail}
      disabled={loading}
      small={true}
      bg="bg-white"
      disabledBg="bg-white"
      textColor="text-blue"
      border="border-blue">
      {loading && <div className="w-4 h-4 border-2 border-gray-200 rounded-full border-t-blue animate-spin" />}
    </Button>
  );
};
