import { useState } from 'react';

import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { EmailButton } from './EmailButton.jsx';
import { DataTableColumnHeader } from '../DataTable/DataTableColumnHeader';
import { DataTablePagination } from '../DataTable/DataTablePagination';
import Heading from '../Heading';
import Loader from '../Loader';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

const DriverTable = ({ drivers, getDrivers, loading, error }) => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const columns = [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
      cell: ({ row }) => {
        const driver = row.original;
        return (
          <p className="font-bold whitespace-nowrap">
            {driver.firstName} {driver.lastName}
          </p>
        );
      },
      enableSorting: false
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
      cell: ({ row }) => {
        const driver = row.original;
        return <p className="whitespace-nowrap">{driver?.email}</p>;
      },

      enableSorting: false
    },
    {
      accessorKey: 'phoneNumber',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Phone Number" />,
      cell: ({ row }) => {
        const driver = row.original;
        return <p className="whitespace-nowrap">{driver.phoneNumber ? driver.phoneNumber : '-'}</p>;
      },
      enableSorting: false
    },
    {
      accessorKey: 'fleet.name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Fleet" />,
      cell: ({ row }) => {
        const driver = row.original;
        return <p className="whitespace-nowrap">{driver.fleet.name}</p>;
      },
      enableSorting: true
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
      cell: ({ row }) => {
        const driver = row.original;
        return <p className="whitespace-nowrap">{dayjs(driver.createdAt).format('MMM DD, YYYY')}</p>;
      },
      enableSorting: true
    },
    {
      accessorKey: 'emailButton',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Password Email" />,
      cell: ({ row }) => {
        const driver = row.original;
        return <EmailButton driver={driver} getDrivers={getDrivers} />;
      },
      enableSorting: false
    }
  ];

  const table = useReactTable({
    data: drivers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters
    }
  });

  return (
    <div>
      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col gap-2">
          <Heading text={`Total Drivers (${drivers.length})`} />
          <div className="text-sm text-gray-500">*Showing only active drivers</div>
        </div>
        <Input
          placeholder={`Search by driver name...`}
          value={table.getColumn('email')?.getFilterValue() ?? ''}
          onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />
      </div>
      <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[800px]">
        <div className="relative rounded-3xl">
          {loading ? (
            <div className="flex items-center justify-center w-full my-20">
              <Loader />
            </div>
          ) : error ? (
            <div className="flex items-center justify-center w-full my-5">
              <p className="text-gray-500">{error}</p>
            </div>
          ) : (
            <Table>
              <TableHeader className="border-gray-300 border-b-[1.5px] bg-slate-50">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead className="whitespace-nowrap" key={header.id}>
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} className={`border-gray-300 border-b-[1.5px]`}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-left">
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
};

export default DriverTable;
